// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-cookie-policy-index-jsx": () => import("./../../../src/pages/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-history-[id]-jsx": () => import("./../../../src/pages/my-account/history/[id].jsx" /* webpackChunkName: "component---src-pages-my-account-history-[id]-jsx" */),
  "component---src-pages-my-account-index-jsx": () => import("./../../../src/pages/my-account/index.jsx" /* webpackChunkName: "component---src-pages-my-account-index-jsx" */),
  "component---src-pages-privacy-policy-index-jsx": () => import("./../../../src/pages/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-jsx" */),
  "component---src-pages-social-spy-index-jsx": () => import("./../../../src/pages/social-spy/index.jsx" /* webpackChunkName: "component---src-pages-social-spy-index-jsx" */),
  "component---src-pages-terms-of-use-index-jsx": () => import("./../../../src/pages/terms-of-use/index.jsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-jsx" */)
}

