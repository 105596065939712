import React, { memo, useState } from "react";
import Authentication from ".";
import { UserContext } from "../../context/user-context";

const Context = ({ children }) => {
  const [user, setUser] = useState(null);
  return (
    <UserContext.Provider value={{ user, setUser }}>
      <Authentication>{children}</Authentication>
    </UserContext.Provider>
  );
};

export default memo(Context);
