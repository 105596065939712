import React, { useContext, useEffect, memo, useCallback } from "react";
import { UserContext } from "../../context/user-context";
import { getUser } from "../../requests";

const Authentication = (props) => {
  const { setUser } = useContext(UserContext);

  const getUserInfo = useCallback(async () => {
    const userInfo = await getUser();
    userInfo && setUser(userInfo);
  }, [getUser]);

  useEffect(() => {
    getUserInfo();
  }, []);

  return <>{props.children}</>;
};

export default memo(Authentication);
