import { createContext } from "react";

export interface UserType {
  id: number;
  firstName: string;
  lastName: string;
  password: string;
  email: string;
  createdAt: string;
  updatedAt: string;
}

export const UserContext = createContext({
  user: null as UserType,
  setUser: (user: UserType) => {},
});
